
























import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'NewsFavorite',
  props: {
    count: {
      default: 0,
      type: Number as PropType<number>,
    },
    url: {
      default: '',
      type: String as PropType<string>,
    },
    handlerMouseover: {
      required: true,
      type: Function as PropType<Function>,
    },
    handlerMouseleave: {
      required: true,
      type: Function as PropType<Function>,
    },
    handlerClick: {
      required: true,
      type: Function as PropType<Function>,
    },
  },
});
